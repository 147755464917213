import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { forkJoin, map } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';

export const CompanyRoleFeatureGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot
) => {
  const authService = inject(AuthService);
  return forkJoin([
    authService.isWasticsAdmin$,
    authService.canActivatePath(next.routeConfig.path),
  ]).pipe(
    map(
      ([isWasticsAdmin, canActivatePath]) => isWasticsAdmin || canActivatePath
    )
  );
};
