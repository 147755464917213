import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppMaterialModule } from './app-material.module';
import { RowComponent } from 'src/app/components/shared/row/row.component';
import { ColComponent } from 'src/app/components/shared/col/col.component';
import { FooterComponent } from 'src/app/components/layout/footer/footer.component';
import { InputComponent } from 'src/app/components/shared/input/input.component';
import { TableComponent } from 'src/app/components/layout/table/table.component';
import { AdvancedTableComponent } from 'src/app/components/layout/advanced-table/advanced-table.component';
import { SnackbarComponent } from 'src/app/components/layout/snackbar/snackbar.component';
import { TitleComponent } from 'src/app/components/layout/title/title.component';
import { UploadFilesComponent } from 'src/app/components/shared/upload-files/upload-files.component';
import { LoadingComponent } from 'src/app/components/layout/loading/loading.component';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    BrowserModule,
    DragDropModule,
    PdfViewerModule,
    AppRoutingModule,
    AppMaterialModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FileUploadModule,
    ColComponent,
    RowComponent,
    UploadFilesComponent,
    TableComponent,
    AdvancedTableComponent,
    TitleComponent,
    LoadingComponent,
  ],
  declarations: [FooterComponent, InputComponent, SnackbarComponent],
  exports: [
    FormsModule,
    CommonModule,
    BrowserModule,
    DragDropModule,
    PdfViewerModule,
    AppRoutingModule,
    FileUploadModule,
    AppMaterialModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    RowComponent,
    ColComponent,
    FooterComponent,
    InputComponent,
    TableComponent,
    TitleComponent,
    AdvancedTableComponent,
    UploadFilesComponent,
    LoadingComponent,
  ],
})
export class AppComponentModules {}
