import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
  constructor(
    protected override readonly router: Router,
    protected readonly keycloak: KeycloakService
  ) {
    super(router, keycloak);
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    //if User is not logged in force him
    await this.isLoggedIntoKeycloak(state);
    // Get the roles required from the route.
    const requiredRoles = route.data['roles'];
    return this.isRolePresent(requiredRoles);
  }

  private isRolePresent(requiredRoles: any[]): boolean {
    // Allow the user to proceed if no additional roles are required to access the route.
    if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
      return true;
    }
    // Allow the user to proceed if all the required roles are present.
    if (requiredRoles.every((role) => this.roles.includes(role))) {
      return true;
    }

    //Allow the user to proceed if any required role is present.
    for (const element of requiredRoles) {
      if (this.roles.includes(element)) {
        return true;
      }
    }

    // redirect to error page if the user doesn't have the necessary role to access
    this.router.navigate(['access-denied']);
    return false;
  }

  private async isLoggedIntoKeycloak(state: RouterStateSnapshot) {
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url,
      });
    }
  }
}
