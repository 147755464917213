import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const SENTRY_CONFIG: Sentry.BrowserOptions = {
  enabled: environment.production,
  environment: environment.name,
  dsn: 'https://f99f39e3d8d7097a6e487890fbb4085f@o4507606405742592.ingest.de.sentry.io/4507631059730512',
  integrations: environment.traceSession
    ? [Sentry.browserTracingIntegration(), Sentry.replayIntegration()]
    : [],
  //Performance monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/([a-zA-Z0-9-]+)\.wastics\.eu$/],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and the sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
};

Sentry.init(SENTRY_CONFIG);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
