import { inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { Observable, throwError, catchError } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import { LockNavigationService } from 'src/app/shared/services/lock-navigation.service';
import { environment } from 'src/environments/environment';
import { SnackbarService } from 'src/app/shared/utils/snackbar/snackbar.service';

interface Profile extends Keycloak.KeycloakProfile {
  attributes: {
    company: string[];
    wasticsUserId: string[];
  };
}

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  keycloakService = inject(KeycloakService);
  snackbarService = inject(SnackbarService);
  lockNavigationService = inject(LockNavigationService);

  private handleAuthError(request: HttpErrorResponse): Observable<any> {
    if (
      request.status === HttpStatusCode.Unauthorized ||
      request.status === HttpStatusCode.Forbidden
    ) {
      this.lockNavigationService.unlock();
      this.keycloakService.logout(window.location.toString());
    }
    if (
      request.status === HttpStatusCode.PreconditionRequired &&
      request.url.includes(environment.quarkusApiUrl)
    ) {
      this.keycloakService
        .getKeycloakInstance()
        .updateToken(-1)
        .then(() => {
          this.keycloakService.loadUserProfile().then((userprofile) => {
            const { company, wasticsUserId } = (userprofile as Profile)
              .attributes;
            if (!(company && company[0] && wasticsUserId && wasticsUserId[0])) {
              this.snackbarService.open({
                type: 'error',
                message:
                  'Bei diesem Modul ist ein Fehler aufgetreten. Versuchen Sie, die Seite neu zu laden, und wenn es dann immer noch nicht funktioniert, wenden Sie sich bitte an den Support.',
                duration: 10000,
              });
            }
          });
        });
    }
    return throwError(() => request);
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(catchError((x) => this.handleAuthError(x)));
  }
}
