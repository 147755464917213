import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { LockNavigationService } from 'src/app/shared/services/lock-navigation.service';

export const LeavingGuard: CanDeactivateFn<any> = (
  _component,
  _currentRoute,
  currentState,
  nextState
) => {
  const lockNavigationService = inject(LockNavigationService);

  const currentBaseUrl = currentState.url.split('/')[1];
  const nextBaseUrl = nextState.url.split('/')[1];

  if (!lockNavigationService.isLocked) return true;
  if (currentBaseUrl === nextBaseUrl) return true;

  const leave = confirm(
    'Sie haben ungespeicherte Änderungen. Seite verlassen?'
  );
  console.log('Leave:', leave);

  if (leave) {
    lockNavigationService.unlock();
    return true;
  }

  return false;
};
