import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { map } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';

export const RegistrationGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.company$.pipe(
    map((company) => {
      if (!company?.registrationCompleted) return router.parseUrl('/welcome');
      if (!company?.wasticsAgbAccepted) return router.parseUrl('/accept-agb');

      return true;
    })
  );
};
